/* eslint-disable import/no-default-export */
import React from "react";

import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/20/solid";

import type { MarkerPoint } from "~/types";

const LocationTooltip = ({
  pos = "header",
  markerPoints,
  onClose,
}: {
  pos?: "header" | "footer";
  markerPoints: MarkerPoint[];
  onClose: (val: boolean) => void;
}) => (
  <div
    className="absolute z-10 p-4 mt-2 bg-white rounded-xl shadow-lg max-w-xl w-full left-1/2 transform -translate-x-1/2 locationMobile"
    style={{
      left: `${pos === "header" ? "80%" : "250px"}`,
      top: `${pos === "footer" ? "-100px" : "65%"}`,
    }}
  >
    <div className="flex justify-center items-center pb-5">
      <h2 className="text-2xl font-bold font-Inter text-staff-420">
        All locations
      </h2>
      <div onClick={() => onClose(false)}>
      <button
        className="top-6 right-6 self-end"
        data-testid="SAUK-mobile-search-modal-close-btn"
        type="button"
        style={{ position: "absolute" }}
       
      >
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          className=" stroke-black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6L18 18"
            stroke=" "
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      </div>
    </div>
    <div className="flex flex-col items-start justify-center gap-5 pb-5 scollView">
      {markerPoints &&
        markerPoints.map((markerPoint, i) => {
          const data = markerPoint.attributes;
          return (
            <div className="flex items-center gap-5 flex-nowrap ">
              <div className="flex items-center justify-between gap-3 text-staff-400">
                <a
                  className="flex items-center gap-1 text-lg hover:text-staff-320"
                  href={`https://www.google.com/maps/search/?api=1&query=${data.Address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <MapPinIcon width={20} /> {data.City}
                  <div className="text-xl font-bold smallTextForMobile">{data.Name}</div>
                </a>
              </div>
              <div className="flex items-center gap-2 text-xl text-gray-950 font-SourceSans text-nowrap smallTextForMobile">
                <PhoneIcon width={20} />
                {data.Phone}
              </div>
              <div className="flex items-center gap-2 text-xl smallTextForMobile text-gray-950 font-SourceSans">
                <EnvelopeIcon width={20} />
                {data.Email}
              </div>
            </div>
          );
        })}
    </div>
  </div>
);

export default LocationTooltip;
