import { Popover } from "@headlessui/react";

// import { DialogModel } from "./dialogModel/DialogModel";
type Props = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export function BurgerButton({ setIsOpen, isOpen }: Props) {
  return (
    <div className="-my-2 mr-[0.1rem] md:hidden">
      <Popover.Button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center justify-center p-2 text-black rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset "
      >
        <span className="sr-only">Open menu</span>
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1H17M1 7H9M1 13H17"
            className="stroke-black dark:stroke-white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Popover.Button>
    </div>
  );
}
