import React from "react";
type ImageProps = {
  className?: string;
};

export function LogoImage({ className }: ImageProps) {
  return (
    <img
      className={`text-big ${className}`}
      src="/navLogoHeader.svg"
      alt="Rec Source"
    />
  );
}
