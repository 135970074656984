export function ContactIcon({ position }: { readonly position: 'top' | 'footer' }) {
    return (
        <div data-testid="sauk-phone" className="flex items-center justify-center contactHover">
            {position === 'top' ? (
                <>
                    <img className="hidden dark:block" width={30} src="/contact/white-call.png" />
                    <img className="block dark:hidden" width={30} src="/contact/black-call.png" />
                </>
            ) : (
                <>
                    <img className="block" width={30} src="/contact/white-call.png" />
                </>
            )}
        </div>
    );
}
