import * as React from "react";
import { LinkPropsType } from "./facebook";

export function Linkedin(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & LinkPropsType
) {
  return (
    <a
      href={props.url}
      className={
        props.loc == "header"
          ? "fill-black hover:fill-black-2  dark:fill-white hover:"
          : "bg-staff-600 hover:fill-white-2"
      }
    >
      <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          className=""
          d="M5.208 2.609a2.581 2.581 0 01-2.604 2.608A2.581 2.581 0 010 2.61 2.581 2.581 0 012.604 0a2.581 2.581 0 012.604 2.609zm0 4.695H0V24h5.208V7.304zm8.334 0H8.333V24h5.209v-8.765c0-4.905 6.25-5.322 6.25 0V24H25V13.46c0-8.243-9.27-7.93-11.458-3.86V7.304z"
        />
      </svg>
    </a>
  );
}
