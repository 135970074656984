import { useState } from 'react';

import { Link } from '@remix-run/react';
import CookieConsent from 'react-cookie-consent';

import type { GlobalData, MarkerPoint } from '~/types';

import { ContactIcon } from '../icons/contact';
import { Facebook } from '../icons/facebook';
import { Linkedin } from '../icons/linkedinLogo';
import { Instagram } from '../icons/lnstagram';
import { FooterMap } from '../organisms/FooterMap';
import { MultipleContactPopoover } from '../organisms/MultipleContactPopover/MultipleContactPopoover';
import LocationTooltip from '../TooltipLocation';

type NavPayload = {
    title: string;
    path: string;
    id: string;
};

type Props = {
    globalData: GlobalData;
    routePath: string;
    markerPoints: MarkerPoint[];
    googleApiKey: string;
    footerNavOne: NavPayload[];
    footerNavTwo: NavPayload[];
    footerNavThree: NavPayload[];
};

export const Footer = ({
    globalData,
    googleApiKey,
    markerPoints,
    footerNavOne,
    footerNavTwo,
    footerNavThree,
    routePath,
}: Props) => {
    const [contactPanel, setContactPanel] = useState(false);    
    return (
        <div className="w-full px-8 bg-staff-300">
            <CookieConsent
                style={{
                    backgroundColor: 'white',
                    color: 'rgb(3 26 56)',
                    margin: '5px',
                    border: '1px solid rgb(3 26 56)',
                    borderRadius: '8px',
                }}
                buttonStyle={{
                    backgroundColor: 'rgb(3 26 56)',
                    borderRadius: '5px',
                    color: 'white',
                }}
                containerClasses="bg-staff-300"
                buttonText="I understand">
                This site uses cookies.
            </CookieConsent>

            <div className="mx-auto max-w-7xl">
                {routePath.indexOf('contact-us') < 0 ? (
                    <div className="py-10 ">
                        <FooterMap markerPoints={markerPoints} googleApiKey={googleApiKey} />
                    </div>
                ) : (
                    ''
                )}
                <div className="items-start justify-between md:flex relative">
                    <div>
                        <div className="mt-5 ">
                            <span className="text-xl font-semibold text-staff-320">Navigation</span>
                        </div>
                        <div className="flex justify-start mt-5 mb-12 space-x-10">
                            <span
                                data-testid="sauk-footer-nav-one"
                                className="flex flex-col space-y-4 text-base font-semibold font-SourceSans text-staff-320">
                                {footerNavOne &&
                                    footerNavOne.map((page) => (
                                        <Link prefetch='render' to={page.path} key={page.id}>
                                            {' '}
                                            <span className="hover:text-white">{page.title}</span>
                                        </Link>
                                    ))}
                            </span>
                            <div
                                data-testid="sauk-footer-nav-two"
                                className="flex flex-col space-y-4 text-base font-semibold font-SourceSans text-staff-320">
                                {footerNavTwo &&
                                    footerNavTwo.map((page) => (
                                        <Link prefetch='render' to={page.path} key={page.id}>
                                            {' '}
                                            <span className="hover:text-white">{page.title}</span>
                                        </Link>
                                    ))}
                            </div>
                            <div
                                data-testid="sauk-footer-nav-three"
                                className="flex flex-col space-y-4 text-base font-semibold font-SourceSans text-staff-320">
                                {footerNavThree &&
                                    footerNavThree.map((page) => (
                                        <Link prefetch='render' to={page.path} key={page.id}>
                                            {' '}
                                            <span className="hover:text-white">{page.title}</span>
                                        </Link>
                                    ))}
                            </div>
                        </div>
                        <div data-testid="sauk-nav-icons" className="flex items-center justify-start my-8 space-x-4">
                            {globalData.data.attributes.SocialLinks[0].Link != null ? (
                                <div className="cursor-pointer">
                                    <Facebook url={globalData.data.attributes.SocialLinks[0].Link} loc="footer" />
                                </div>
                            ) : (
                                ''
                            )}
                            {globalData.data.attributes.SocialLinks[1].Link != null ? (
                                <div className="cursor-pointer">
                                    <Instagram url={globalData.data.attributes.SocialLinks[1].Link} loc="footer" />
                                </div>
                            ) : (
                                ''
                            )}
                            {globalData.data.attributes.SocialLinks[2].Link != null ? (
                                <div className="cursor-pointer">
                                    <Linkedin url={globalData.data.attributes.SocialLinks[2].Link} loc="footer" />
                                </div>
                            ) : (
                                ''
                            )}
                            {globalData.data.attributes.SocialLinks[3].Link != null && (
                                <div className="flex items-center justify-center space-x-2"
                                onMouseEnter={() => {
                                    setContactPanel(true);    
                                }}
                                onMouseLeave={() => {
                                     setContactPanel(false);
                                }}
                                >
                                    <button
                                        className="flex items-center justify-center space-x-2"
                                       >
                                        <ContactIcon position="footer" />

                                        {/* <MultipleContactPopoover
                                            markerPoints={markerPoints}
                                            setIsOpen={setContactPanel}
                                            isOpen={contactPanel}
                                            closeModal={() => setContactPanel(false)}
                                        /> */}
                                    </button>
                                    {contactPanel && <LocationTooltip pos='footer' markerPoints={markerPoints} onClose={setContactPanel}/>}

                                </div>
                            )}
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-y-2 md:mt-10 gap-x-2 md:grid-cols-4 ">
                        {/* <div className=" p-1  w-full  h-[57px] bg-white flex justify-center rounded-md">
                            <img src="/partners/image 6.svg" alt="partener" className="w-20 h-full " />
                        </div> */}
                        <div className="flex justify-center w-full py-1 bg-white rounded-md h-14">
                            <img src="/partners/image 7.svg" alt="partener" />
                        </div>
                        <div className="flex items-center justify-center w-full py-1 bg-white rounded-md h-14">
                            <img src="/partners/image 9.svg" alt="partener" className=" w-28 h-9" />
                        </div>
                        <div className="flex justify-center w-full py-1 bg-white rounded-md h-14">
                            <img src="/partners/image 8.svg" alt="partener" />
                        </div>
                    </div>
                </div>

                <div className="mt-6 mb-3">
                    <span className="text-xs font-semibold text-white font-SourceSans">
                        &copy; {new Date().getFullYear()} RecSource LTD
                    </span>
                </div>
                <div className="h-[1px]   mb-3 w-full bg-staff-250"></div>
                <div className="flex justify-between pb-4 mt-2 mb-2">
                    <Link prefetch='render' to="/">
                        <img src="/logoFooterRec.svg" alt="logofooter" />
                    </Link>
                </div>
            </div>
        </div>
    );
};
