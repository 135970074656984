import { useEffect, useMemo, useState } from "react";

import { Switch } from "@headlessui/react";

import { Theme, useTheme } from "utils/theme-provider";

export const Switcher = () => {
  const [data, setTheme] = useTheme();

  const [toggleTheme, setToggleTheme] = useState(data === Theme.DARK);

  const prevTheme = data;
  const toggleThemeHandler = () => {
    setToggleTheme((prevTheme) => !prevTheme);

    setTheme((prevTheme: unknown) =>
      prevTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
    );
  };

  return (
    <>
      <Switch
        checked={toggleTheme}
        onChange={toggleThemeHandler}
        className={`${
          prevTheme !== Theme.LIGHT
            ? "bg-staff-400 border-white"
            : "bg-white border-staff-200"
        } relative inline-flex   h-10  w-20 items-center rounded-full   border-4 `}
      >
        <span
          className={`${
            prevTheme !== Theme.LIGHT
              ? "translate-x-10  bg-mon "
              : "translate-x-1 bg-sun  z-50 "
          } inline-block h-8 w-8  bg-center bg-staff-200 bg-no-repeat  transform rounded-full   z-40  bottom-4 border-white  transition`}
        />
      </Switch>
    </>
  );
};
