import * as React from "react";

export type LinkPropsType = {
  url: string;
  loc: string;
};
export function Facebook(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & LinkPropsType
) {
  return (
    <a
      href={props.url}
      className={
        props.loc == "header"
          ? "fill-black hover:fill-black-2  dark:fill-white hover:"
          : "bg-staff-600 hover:fill-white-2"
      }
    >
      <svg
        width={12}
        height={24}
        viewBox="0 0 12 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          // fill={props.fill || "#000"}
          className=""
          d="M3 8H0v4h3v12h5V12h3.6l.4-4H8V6.3C8 5.4 8.2 5 9.1 5H12V0H8.2C4.6 0 3 1.6 3 4.6V8z"
        />
      </svg>
    </a>
  );
}
